'use client';
import {
  NextButton,
  PrevButton,
  usePrevNextButtons,
} from '@components/Carousel//Carousel.Arrows';
import {
  CarouselPageButton,
  useCarouselPageButton,
} from '@components/Carousel/Carousel.Pagination';
import clsx from 'clsx';
import useEmblaCarousel, {
  type UseEmblaCarouselType,
} from 'embla-carousel-react';
import React from 'react';

interface CarouselProps {
  items: React.ReactNode[];
  className?: string;
  showOverflow?: boolean;
  hero?: boolean;
  pagination?: boolean;
}

export type EmblaCarouselApi = UseEmblaCarouselType[1];
export type EmblaUseCarouselParameters = Parameters<typeof useEmblaCarousel>;
export type EmblaCarouselOptions = EmblaUseCarouselParameters[0];
export type EmblaCarouselPlugin = EmblaUseCarouselParameters[1];

export const Carousel: React.FC<CarouselProps> = ({
  items,
  showOverflow,
  hero,
  pagination = true,
  className,
}) => {
  const options: EmblaCarouselOptions = { loop: false, slidesToScroll: 'auto' };
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useCarouselPageButton(emblaApi);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  return (
    <div
      className={clsx(
        !hero &&
          '-z-10 -mx-4 -mt-6 overflow-hidden pt-10 md:mx-0 md:-mt-10 md:overflow-visible',
        hero && 'h-full'
      )}>
      <div
        className={clsx(
          'carousel relative',
          hero && 'carousel-hero h-full',
          className
        )}>
        <div
          className={clsx(
            'carousel-overflow h-full w-full md:overflow-hidden',
            hero ? 'rounded-xl p-0' : 'px-9 pb-3 md:pb-6 md:pt-6',
            showOverflow && 'overflow-visible'
          )}
          ref={emblaRef}>
          <div className="carousel-container -mx-10 grid h-full auto-cols-[100%] grid-flow-col px-4 md:auto-cols-[50%] md:px-0 lg:auto-cols-[33.333%]">
            {items.map((item, index) => (
              <div
                className="carousel-slide relative flex w-full px-1 last:pr-4 md:px-2 md:last:pr-2"
                key={index}>
                {item}
              </div>
            ))}
          </div>
        </div>

        <div className="carousel-controls">
          {scrollSnaps.length > 1 && (
            <div
              className={clsx('carousel-buttons', !hero && 'hidden md:block')}>
              <PrevButton
                onClick={onPrevButtonClick}
                disabled={prevBtnDisabled}
              />
              <NextButton
                onClick={onNextButtonClick}
                disabled={nextBtnDisabled}
              />
            </div>
          )}

          {pagination && scrollSnaps.length > 1 && (
            <div className="carousel-pagination-bullets flex justify-center space-x-2 pt-2 md:pt-0">
              {scrollSnaps.map((_, index) => (
                <CarouselPageButton
                  key={index}
                  onClick={() => onDotButtonClick(index)}
                  className={clsx(
                    'h-2 w-2 cursor-default rounded-lg md:h-1.5 md:w-8 md:cursor-pointer',
                    index === selectedIndex
                      ? 'bg-brand-mothernature opacity-100'
                      : 'bg-gray-400 opacity-50'
                  )}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
