'use client';
import ImageWrapper from '@components/Image';
import Logo from '@components/Logo';
import { Sheet, SheetContent, SheetTrigger } from '@components/Sheet';
import SocialIcons from '@components/SocialIcons';
import { Typography } from '@components/Typography';
import slugToURL from '@helpers/SlugToURL';
import {
  ArrowNarrowLeft,
  ArrowNarrowRight,
  ChevronDown,
  Menu01,
  SearchMd,
  X,
} from '@untitled-ui/icons-react';
import clsx from 'clsx';
import Link from 'next/link';
import React, { useState } from 'react';
import { userStore } from 'store/UserStore';
import { NavItem, NavItemMenuItemsItem } from 'types/graphql/contentful';

interface NavbarDesktopProps {
  navData: NavItem[];
  className?: string;
}

type FeaturedLinkProps = {
  link: string;
  image: string;
  title: string;
  setCurrentMenu: React.Dispatch<React.SetStateAction<string | null>>;
};

interface MenuItemProps {
  menuItem: NavItemMenuItemsItem;
  type: 'vsc' | 'atdw';
  className?: string;
  setCurrentMenu: React.Dispatch<React.SetStateAction<string | null>>;
}

const StringToColor: Record<string, { name: string }> = {
  LILLYPILLY: { name: 'bg-brand-lillypilly/40' },
  MOTHERNATURE: { name: 'bg-brand-mothernature/20' },
  SAND: { name: 'bg-brand-sand' },
};

const DropDownHeading: React.FC<{ heading: string }> = React.memo(
  ({ heading }) => (
    <Typography
      tag="p"
      size="text-2xl"
      weight="font-semibold"
      className="block text-black md:hidden">
      {heading}
    </Typography>
  )
);

const MenuItem: React.FC<MenuItemProps> = React.memo(
  ({ menuItem, type, className, setCurrentMenu }) => {
    let link, image, title, summary;

    switch (menuItem.__typename) {
      case 'NavigationLink':
        link = slugToURL(menuItem.__typename, menuItem.linkUrl);
        image = menuItem?.image?.url;
        title = menuItem.linkTitle;
        break;
      case 'Guide':
      case 'Page':
        link = slugToURL(menuItem.__typename, menuItem.slug);
        image = menuItem.heroCollection?.items?.[0]?.image?.url;
        title = menuItem.title;
        summary = menuItem.summary;
        break;
      case 'Place':
        link = slugToURL(menuItem.__typename, menuItem.slug);
        image = menuItem.heroCollection?.items?.[0]?.image?.url;
        title = menuItem.name;
        summary = menuItem.summary;
        break;
    }

    return (
      <li
        className={clsx('flex h-20 md:basis-1/2', className)}
        key={`subnav-${menuItem?.sys?.id}`}>
        <Link
          href={link}
          className="nice-transition relative flex w-full items-center space-x-4 rounded-md pr-4 hover:-translate-y-1"
          onClick={() => setCurrentMenu(null)}>
          <div className="h-20 w-36 shrink-0 overflow-hidden rounded-md bg-gray-100">
            {image && (
              <ImageWrapper
                dimensions={{ width: 400, height: 400 }}
                src={image}
                cover
                fullHeight
                fullWidth
                className=""
              />
            )}
          </div>
          <div className="flex flex-col space-y-2">
            <Typography tag="p" size="text-lg" weight="font-semibold">
              {title}
            </Typography>
          </div>
        </Link>
      </li>
    );
  }
);

const FeaturedLink: React.FC<FeaturedLinkProps> = React.memo(
  ({ link, image, title, setCurrentMenu }) => (
    <li className="bg-red flex py-1 md:basis-1/2" key={`subnav-${link}`}>
      <Link
        href={
          link?.startsWith('http')
            ? link
            : link?.startsWith('/')
              ? link
              : `/${link}`
        }
        className="nice-transition relative flex w-full items-center space-x-4 rounded-md pr-4 hover:-translate-y-1"
        onClick={() => setCurrentMenu(null)}>
        {image && (
          <div className="h-20 w-36 shrink-0 overflow-hidden rounded-md bg-gray-100">
            {image && (
              <ImageWrapper
                dimensions={{ width: 400, height: 400 }}
                src={image}
                cover
                fullHeight
                fullWidth
              />
            )}
          </div>
        )}
        <Typography tag="p" size="text-lg" weight="font-semibold">
          {title}
        </Typography>
      </Link>
    </li>
  )
);

const SideNavigation: React.FC<NavbarDesktopProps> = ({
  navData,
  className,
}) => {
  const { uuid, favourites } = userStore();
  const [currentMenu, setCurrentMenu] = useState<string | null>(null);

  const TopNavItems: React.FC = React.memo(() => (
    <nav role="navigation" aria-label="Main Menu">
      <ul className="hidden space-x-8 md:w-full lg:flex">
        {navData.slice(0, 3).map((navItem) =>
          navItem.menuItemsCollection?.items.length > 0 ? (
            <li key={`trigger-${navItem.sys?.id}`}>
              <SheetTrigger
                onClick={() => setCurrentMenu(navItem.sys?.id)}
                className="flex items-center text-lg hover:underline">
                {navItem.title}
                <ChevronDown
                  className="ml-2 mt-0.5 size-4"
                  role="presentation"
                />
              </SheetTrigger>
            </li>
          ) : (
            <li key={`trigger-${navItem.sys?.id}`}>
              <Link href={navItem.linksTo} className="text-lg hover:underline">
                {navItem.title}
              </Link>
            </li>
          )
        )}
      </ul>
    </nav>
  ));

  return (
    <div
      className={clsx(
        'absolute left-0 right-0 top-0 z-20 w-full px-4 py-2',
        className
      )}>
      <Link
        href="/"
        title="Visit homepage"
        className="absolute left-1/2 top-3 z-50 -ml-6 md:top-2 md:-ml-12">
        <Logo
          className={clsx(
            'text-brand-ginger transition-all duration-300 ease-in-out hover:text-brand-mothernature'
          )}
          layout="stacked"
          size="base"
        />
      </Link>
      <div
        className={clsx(
          'mx-auto flex w-full max-w-7xl justify-between pt-4 md:pt-6'
          //pathname === '/' && 'text-white'
        )}>
        <Sheet open={currentMenu !== null}>
          <div className="align-start flex items-center gap-x-8">
            <SheetTrigger
              onClick={() => setCurrentMenu('burger')}
              aria-label="Menu">
              <Menu01
                className="nice-transition relative z-40 mr-2 size-6 cursor-pointer"
                role="presentation"
              />
            </SheetTrigger>

            <TopNavItems />
          </div>
          <SheetContent
            side="left"
            className={clsx('flex h-full w-full md:max-w-none')}
            onEscapeKeyDown={() => setCurrentMenu(null)}>
            <div
              className={clsx(
                'relative flex w-full shrink-0 flex-col justify-between overflow-y-auto bg-white p-6 text-2xl font-normal md:w-[420px] md:p-8',
                currentMenu !== 'burger' && 'hidden md:flex'
              )}>
              <div className="flex flex-col items-start gap-y-8">
                <Link href="/" onClick={() => setCurrentMenu(null)}>
                  <Logo
                    className={clsx('text-brand-ginger')}
                    layout="horizontal"
                    size="lg"
                  />
                </Link>
                <X
                  className="absolute right-6 top-7 size-8 cursor-pointer md:top-9 md:size-10"
                  onClick={() => setCurrentMenu(null)}
                  role="presentation"
                />
                {navData.map((navItem) =>
                  navItem.menuItemsCollection?.items.length > 0 ? (
                    <div
                      className={clsx(
                        'group flex w-full cursor-pointer items-center justify-between hover:underline',
                        navItem.sys?.id === currentMenu && 'underline'
                      )}
                      onClick={() => setCurrentMenu(navItem.sys?.id)}
                      key={`nav-${navItem.sys?.id}`}>
                      <span className="block">{navItem.title}</span>
                      <ArrowNarrowRight
                        className="nice-transition size-6 group-hover:translate-x-2"
                        role="presentation"
                      />
                    </div>
                  ) : (
                    <SheetTrigger key={`nav-${navItem.sys?.id}`}>
                      <Link
                        href={navItem.linksTo}
                        onClick={() => setCurrentMenu(null)}
                        className="hover:underline">
                        {navItem.title}
                      </Link>
                    </SheetTrigger>
                  )
                )}
                <hr className="border-b-none my-2 w-full border-t border-t-brand-wintersunrise md:my-8" />
                <Link
                  href="/sustainability"
                  onClick={() => setCurrentMenu(null)}
                  className="text-2xl hover:underline">
                  Sustainability
                </Link>
                <Link
                  href="/visitor-information"
                  onClick={() => setCurrentMenu(null)}
                  className="text-2xl hover:underline">
                  Visitor Information
                </Link>
                <Link
                  href="/contact-us"
                  onClick={() => setCurrentMenu(null)}
                  className="text-2xl hover:underline">
                  Contact Us
                </Link>
              </div>
              <SocialIcons className="justify-between space-x-10 pt-8 md:justify-start md:pt-0" />
            </div>
            {navData.map((navItem) => (
              <div
                className={clsx(
                  currentMenu !== navItem.sys?.id && 'hidden',
                  'flex w-full flex-col overflow-y-scroll p-6 md:p-16',
                  navItem.dropdownColour
                    ? StringToColor[
                        navItem.dropdownColour.replace(/\s/g, '').toUpperCase()
                      ]?.name
                    : 'bg-brand-sand'
                )}
                key={`dropdown-${navItem.title}`}>
                <div className="pb-4">
                  <ArrowNarrowLeft
                    className="nice-transition block size-8 group-hover:translate-x-1 md:hidden"
                    onClick={() => setCurrentMenu('burger')}
                    role="presentation"
                  />
                </div>
                <div className="flex w-full items-center justify-between pb-8">
                  <span>
                    <DropDownHeading heading={navItem.title} />
                  </span>
                  <Link
                    href={navItem.linksTo}
                    className="group flex items-center justify-end space-x-2 hover:underline"
                    onClick={() => setCurrentMenu(null)}>
                    <Typography
                      tag="h3"
                      size="text-base"
                      weight="font-semibold">
                      View All
                    </Typography>
                    <ArrowNarrowRight
                      className="nice-transition size-4 group-hover:translate-x-1"
                      role="presentation"
                    />
                  </Link>
                </div>
                <ul className="flex w-full flex-wrap content-start gap-y-8">
                  {navItem.menuItemsCollection?.items.map(
                    (subNavItem: any, index) => {
                      return subNavItem ? (
                        <MenuItem
                          key={`${subNavItem.__typename}-${subNavItem?.slug || subNavItem?.linkUrl}`}
                          menuItem={subNavItem}
                          type="vsc"
                          setCurrentMenu={setCurrentMenu}
                        />
                      ) : null;
                    }
                  )}
                </ul>
                {navItem?.featuredLinksCollection?.items.length > 0 && (
                  <div className="pt-8">
                    <div className="divider-lines" />

                    <div className="pt-8">
                      <DropDownHeading heading="Featured" />
                      <ul className="flex w-full flex-wrap content-start gap-y-4">
                        {navItem?.featuredLinksCollection?.items.map(
                          (featuredItem) => (
                            <FeaturedLink
                              key={featuredItem.linkUrl}
                              link={featuredItem.linkUrl}
                              image={featuredItem.image?.url}
                              title={featuredItem.linkTitle}
                              setCurrentMenu={setCurrentMenu}
                            />
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </SheetContent>
        </Sheet>

        <div className="relative z-40 flex items-center space-x-4 transition-colors">
          <Link
            href="/search"
            aria-label="Search Visit Sunshine Coast"
            prefetch={false}
            title="Search Visit Sunshine Coast">
            <SearchMd className="h-6 w-6" role="presentation" />
          </Link>
          {uuid && (
            <Link
              href={`/wishlist/${uuid}`}
              aria-label="My Wishlist"
              prefetch={false}
              title="View my Wishlist"
              className="flex items-center space-x-1 text-xs font-bold">
              {favourites?.length > 0 && <span>{favourites.length}</span>}
              <span
                className={clsx('heart size-6', favourites.length && 'hearted')}
                role="presentation"
              />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default SideNavigation;
