'use client';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@components/Collapsible';
import DateRange from '@components/Search/DateRange';
import Hits from '@components/Search/Hits';
import RefinementList from '@components/Search/RefinementList';
import ResetFilters from '@components/Search/ResetFilters';
import Typography from '@components/Typography';
import { ChevronDown } from '@untitled-ui/icons-react';
import algoliasearch from 'algoliasearch/lite';
import React from 'react';
import { Configure } from 'react-instantsearch';
import { InstantSearchNext } from 'react-instantsearch-nextjs';
import { userStore } from 'store/UserStore';
import { AttributesToRetrieve, HideFilters } from 'types/Algolia';

export type InnerSearchProps = {
  searchType:
    | 'deals'
    | 'guides'
    | 'events'
    | 'tours'
    | 'attractions'
    | 'accommodation'
    | 'attractions and tours';
  numberResults?: number;
};

// TODO: Add filters as a prop
const InnerSearch: React.FC<InnerSearchProps> = ({
  searchType,
  numberResults = 18,
}) => {
  let query;
  switch (searchType) {
    case 'deals':
      query = 'productCategoryId:DEAL';
      break;
    case 'guides':
      query = 'productCategoryId:GUIDE';
      break;
    case 'events':
      query = 'productCategoryId:EVENT';
      break;
    case 'attractions':
      query = 'productCategoryId:ATTRACTION';
      break;
    case 'accommodation':
      query = 'productCategoryId:ACCOMM';
      break;
    case 'tours':
      query = 'productCategoryId:TOUR';
      break;
    case 'attractions and tours':
      query = '(productCategoryId:TOUR OR productCategoryId:ATTRACTION)';
      break;
  }

  const { uuid } = userStore();

  return (
    <div className="not-prose pb-16">
      <InstantSearchNext
        searchClient={algoliasearch(
          'GN3FTQV485',
          process.env.NEXT_PUBLIC_ALGOLIA_VSC_KEY
        )}
        indexName={process.env.NEXT_PUBLIC_ALGOLIA_INDEX}
        insights={true}
        future={{
          preserveSharedStateOnUnmount: true,
        }}
        routing={{
          router: {
            cleanUrlOnDispose: false,
          },
        }}>
        <Configure
          facetFilters="owningOrganisationNumber:-OR0052562"
          filters={`${HideFilters} AND ${query}`}
          attributesToRetrieve={AttributesToRetrieve}
          //numericFilters={[`endDateTimeStamp < 0`]}
          hitsPerPage={numberResults}
          analytics={true}
          clickAnalytics
          enablePersonalization
          userToken={uuid ? uuid.toString() : 'anonymous-user'}
        />

        <div className="mb-8 rounded-lg border border-brand-sand bg-brand-sand/20 p-4">
          <Collapsible>
            <CollapsibleTrigger className="flex w-full items-center justify-between">
              <Typography
                tag="p"
                size="text-xl"
                weight="font-semibold"
                className="capitalize">
                Filter
              </Typography>
              <div className="flex items-center space-x-4">
                <ChevronDown className="size-6" role="presentation" />
              </div>
            </CollapsibleTrigger>
            <CollapsibleContent className="pt-4">
              <div className="flex flex-col items-start space-y-6 border-t border-t-brand-sand py-4 md:flex-row md:space-x-12 md:space-y-0">
                <div className="w-full md:w-1/3">
                  <RefinementList
                    label="Area"
                    attribute="addresses.cityName"
                    showMore
                    limit={6}
                  />
                </div>
                {searchType === 'attractions and tours' && (
                  <div className="w-full md:w-1/3">
                    <RefinementList
                      label="Category"
                      attribute="productCategoryId"
                      showMore
                      limit={6}
                    />
                  </div>
                )}
                {searchType === 'events' && (
                  <div className="w-full md:w-1/4">
                    <DateRange attribute="endDateTimestamp" />
                  </div>
                )}
                {searchType === 'deals' && (
                  <div className="w-full md:w-1/3">
                    <RefinementList
                      label="Deal Type"
                      attribute="parentProductCategoryId"
                      showMore
                      limit={6}
                    />
                  </div>
                )}
                {searchType === 'accommodation' && (
                  <div className="w-full md:w-1/3">
                    <RefinementList
                      label="Accommodation Type"
                      attribute="verticalClassifications.productTypeId"
                      showMore
                      limit={6}
                    />
                  </div>
                )}
                {searchType === 'guides' && (
                  <>
                    <div className="w-full md:w-1/3">
                      <RefinementList
                        label="I'm Interested In"
                        attribute="contentPillar"
                        showMore
                        limit={6}
                      />
                    </div>
                    <div className="w-full md:w-1/3">
                      <RefinementList
                        label="I'm Travelling With"
                        attribute="travellerTypes"
                        showMore
                        limit={6}
                      />
                    </div>
                  </>
                )}
                <div className="justify-left flex w-full md:w-1/4 md:justify-end">
                  <ResetFilters />
                </div>
              </div>
            </CollapsibleContent>
          </Collapsible>
        </div>
        {/* <NumberOfHits resultsText="Deals" /> */}
        <Hits />
      </InstantSearchNext>
    </div>
  );
};

export default InnerSearch;
